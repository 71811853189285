import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { Helmet } from "react-helmet";
import logoLarge from "../img/logos/RxCapture_Logo_Large.png";
import pieChart1 from "../img/rx-capture-pie-chart-1.png"
import pieChart2 from "../img/rx-capture-pie-chart-2.png"
import Workflow_Graphic_1 from "../img/Workflow_Graphic_1.png"
import Workflow_Graphic_2 from "../img/Workflow_Graphic_2.png"
import Workflow_Graphic_3 from "../img/Workflow_Graphic_3.png"
import Workflow_Graphic_4 from "../img/Workflow_Graphic_4.png"
import Workflow_Graphic_5 from "../img/Workflow_Graphic_5.png"
import arrow_right from "../img/arrow-right.png"



const RxCapturePage = ({ data }) => {
  const { markdownRemark: page } = data;

  return (
    <Layout
      headerTitle={page.frontmatter.heading}
      headerClassName="bg-oceanmist curve-br"
      className="accent-oceanmist"
    >
      <Helmet titleTemplate="%s">
        <title>{`${page.frontmatter.title}`}</title>
        <meta property="og:title" content={page.frontmatter.title} />
      </Helmet>
      <section className="container mt-8 mb-8 md:pb-12 lg:pb-8">
        <div className="columns">
          <div className="w-full md:w-5/12 lg:w-5/12">
            <p className="text-2xl text-charcoal leading-relaxed my-3">
              {page.frontmatter.lead_paragraph}
            </p>
          </div>
          <div className="w-full md:w-7/12 lg:w-5/12">
            <PreviewCompatibleImage
              imageInfo={{
                className: "rounded-xl w-3/4 m-auto",
                image: logoLarge,
                alt: "RxCapture Logo",
              }}
            />
          </div>
        </div>
        <div className="columns">
          <Link
                to={page.frontmatter.lead_cta_action}
                className="btn mt-16 btn-accent btn-avenir-semibold md:w-full lg:w-4/12 bg-mustard border-mustard"
              >
                {page.frontmatter.lead_cta}
          </Link>
        </div>
      </section>
      <section className="container pb-8 md:pb-12 lg:pb-16">
        <div className="columns">
          <div className="w-full lg:w-10/12">
            <h1 className="font-rockwell font-medium text-oceanmist text-2xl lg:text-4xl lg:mb-0 text-accent-m1 leading-tight">
              {page.frontmatter.subtitle}
            </h1>
            <p class="text-xl mt-6 text-charcoal">
                By implementing RxCapture, pharmacies, health systems, PBMs, and pharmaceutical manufacturers 
                can ensure their patients receive their medications on time.
            </p>
            <p class="text-xl mt-6 text-charcoal">
                Proactive prescription delivery can reduce return-to-stock, reduce overall healthcare spend,
                and get patients started on therapy more quickly.
            </p>
          </div>
        </div>
      </section>
      <section className="container pb-8 md:pb-12 lg:pb-16">
        <div className="bg-sand rounded-2xl lg:mx-24">
          <div className="columns">
            <div className="m-8 mr-0 w-5/12">
            <PreviewCompatibleImage
              imageInfo={{
                className: "rounded-xl w-3/4 m-auto",
                image: pieChart1,
                alt: "Pie Chart",
              }}
            />
            </div>
            <div className="m-8 w-7/12 lg:w-6/12 mx-0">
              <h1 className="font-display font-bold text-berry text-4xl lg:text-6xl lg:mb-0 text-accent-m1 leading-tight">
                8-16%
              </h1>
              <p class="text-l lg:text-3xl mt-4 text-charcoal">
                of all prescriptions are
              </p>
              <p class="text-l lg:text-3xl text-charcoal">
                completely abandoned*
              </p>
            </div>
          </div>
          <div className="columns">
            <div className="m-8 mr-0 w-5/12">
            <PreviewCompatibleImage
              imageInfo={{
                className: "rounded-xl w-3/4 m-auto",
                image: pieChart2,
                alt: "Pie Chart",
              }}
            />
            </div>
            <div className="m-8 w-7/12 lg:w-6/12 mx-0">
              <h1 className="font-display font-bold text-berry text-4xl lg:text-6xl lg:mb-0 text-accent-m1 leading-tight">
                20%
              </h1>
              <p class="text-l lg:text-3xl text-charcoal mt-4">
                of all prescriptions are not picked up within 72 hours of dispensing**
              </p>
            </div>
          </div>
          <div className="p-8 text-oceanmist italic font-medium">
            <p>*Data provided by Association for Accessible Medicines (2018) and national e-prescribing partner (2022)</p>
            <p>**Data provided by national e-prescribing partner, based on data from major retail chain (2022)</p>
          </div>
        </div>
      </section>
      <section className="container pb-8 md:pb-12 lg:pb-16">
        <div className="columns">
          <div className="w-full my-12 lg:my-0 lg:w-10/12">
            <h1 className="font-rockwell font-medium text-oceanmist text-2xl lg:text-4xl lg:mb-0 text-accent-m1 leading-tight">
              Go Beyond SMS Reminders
            </h1>
            <p class="text-xl mt-6 text-charcoal">
                Instead of letting prescriptions collect dust and get returned to stock,
                RxCapture will send a home delivery offer to eligible patients after a 
                specific number of days. If the patients accept the offer, ScriptDrop
                arranges delivery from the pharmacy to the patient's home.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-dune">
        <div className="flex flex-wrap">
          <div className="w-1/2 md:w-2/12 lg:w-2/12 mt-4 lg:mt-12 ml-auto">
            <div className="rounded-xl w-3/5 m-auto h-32">
              <PreviewCompatibleImage
                imageInfo={{
                  image: Workflow_Graphic_1,
                  className: "m-auto w-11/12",
                  alt: "RxCapture Logo",
                }}
              />
            </div>
            <p className="text-center text-xl m-8 align-top">
              Securely ingest will-call data
            </p>
          </div>
          <div className="w-1/2 md:w-2/12 lg:w-2/12 mt-4 lg:mt-12 mx-0">
            <div className="rounded-xl w-3/5 m-auto h-32 relative">
              <img src={arrow_right} className="absolute w-16" style={{"top": '3rem', "left": '-5rem'}} />
              <PreviewCompatibleImage
                imageInfo={{
                  image: Workflow_Graphic_2,
                  className: "m-auto w-5/6",
                  alt: "RxCapture Logo",
                }}
              />
            </div>
            <p className="text-center text-xl m-8 align-top">
              Decision logic applied
            </p>
          </div>
          <div className="w-1/2 md:w-2/12 lg:w-2/12 mt-4 lg:mt-12 mx-0">
            <div className="rounded-xl w-3/5 m-auto h-32 relative">
              <img src={arrow_right} className="absolute w-16 hidden md:inline-block" style={{"top": '3rem', "left": '-6rem'}} />
              <PreviewCompatibleImage
                imageInfo={{
                  image: Workflow_Graphic_3,
                  className: "m-auto",
                  alt: "RxCapture Logo",
                }}
              />
            </div>
            <p className="text-center text-xl  m-8 align-top">
              Text applicable patients with offer link
            </p>
          </div>
          <div className="w-1/2 md:w-2/12 lg:w-2/12 mt-4 lg:mt-12 mx-0">
            <div className="rounded-xl w-3/5 m-auto h-32 relative">
              <img src={arrow_right} className="absolute w-16" style={{"top": '3rem', "left": '-4.5rem'}} />
              <PreviewCompatibleImage
                imageInfo={{
                  image: Workflow_Graphic_4,
                  className: "w-1/2 m-auto",
                  alt: "RxCapture Logo",
                }}
              />
            </div>
            <p className="text-center text-xl m-8 align-top">
              Patient pays copay online
            </p>
          </div>
          <div className="w-1/2 md:w-2/12 lg:w-2/12 m-auto mt-4 lg:mt-12 lg:ml-0 lg:mr-auto">
            <div className="rounded-xl w-3/5 m-auto h-32 relative">
              <img src={arrow_right} className="absolute w-16" style={{"top": '3rem', "left": '-6.5rem'}} />
              <PreviewCompatibleImage
                imageInfo={{
                  image: Workflow_Graphic_5,
                  className: "m-auto",
                  alt: "RxCapture Logo",
                }}
              />
            </div>
            <p className="text-center text-xl m-4 lg:m-8 align-top">
              ScriptDrop delivers medication to patient
            </p>
          </div>
        </div>
      </section>
      <section className="mt-8 mb-8 md:pb-12 lg:mb-16 lg:mt-16">
        <div className="flex flex-wrap justify-center">
          <div className="m-auto my-4 w-10/12 md:w-3/12 md:m-2 lg:w-3/12 lg:m-2 bg-oceanmist rounded-2xl">
            <h2 className="text-4xl font-rockwell font-medium text-center my-8 text-dune">
              Affordable
            </h2>
            <p className="text-2xl text-center my-8 mx-16 text-white">
              No monthly subscription fee or minimums
            </p>
            <p className="text-2xl text-center my-8 mx-16 text-white">
              Standard delivery cost structure
            </p>
          </div>
          <div className="m-auto my-4 w-10/12 md:w-3/12 md:m-2 lg:w-3/12 lg:m-2 bg-oceanmist rounded-2xl">
            <h2 className="text-4xl font-rockwell font-medium text-center my-8 text-dune">
              Customizable
            </h2>
            <p className="text-2xl text-center my-8 mx-16 text-white">
              White-labeled platform
            </p>
            <p className="text-2xl text-center my-8 mx-16 text-white">
              Delivery eligibility can be determined by insurance type, NDCs, etc.
            </p>
          </div>
          <div className="m-auto my-4 w-10/12 md:w-3/12 md:m-2 lg:w-3/12 lg:m-2 bg-oceanmist rounded-2xl">
            <h2 className="text-4xl font-rockwell font-medium text-center my-8 text-dune">
              Trustworthy
            </h2>
            <p className="text-2xl text-center my-8 mx-16 text-white">
              ScriptDrop has made over 11 million successful deliveries
            </p>
            <p className="text-2xl text-center my-8 mx-16 text-white">
              Our extensive courier network provides nationwide coverage
            </p>
          </div>
        </div>
        <div className="flex flex-wrap justify-center">
          <Link
                to={page.frontmatter.lead_cta_action}
                className="btn mt-16 p-4 btn-accent btn-avenir-semibold w-1/2 lg:w-1/4 bg-mustard border-mustard"
              >
                {page.frontmatter.lead_cta}
          </Link>
        </div>
      </section>
    </Layout>
  );
};

export default RxCapturePage;

export const homeDeliveryPageQuery = graphql`
  query HomeDeliveryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        top_intro_content_html
        footer_cta_content_html
        mid_left_column_content_html
        mid_right_column_content_html
        
        feature_1_content_html
        feature_2_content_html
        feature_3_content_html
        feature_4_content_html
      }

      frontmatter {
        title
        accentClass
        heading
        subtitle
        lead_paragraph
        lead_cta
        lead_cta_action
        chart_caption
        chart_description
        chart_image {
          publicURL
        }
        feature_1_icon_name
        feature_1_heading
        feature_2_icon_name
        feature_2_heading
        feature_3_icon_name
        feature_3_heading
        feature_4_icon_name
        feature_4_heading
        footer_cta_heading
        headerPhoto {
          childImageSharp {
            fluid(
              maxWidth: 256
              quality: 80
              traceSVG: { background: "transparent", color: "#339888" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;
